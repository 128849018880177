  <template>
    <div class="warehouse_container">
      <div class="titleNav"> 
         <a-breadcrumb>
             <a-breadcrumb-item>您当前所在的位置：网站首页></a-breadcrumb-item>
             <a-breadcrumb-item><a href="">工具与服务></a></a-breadcrumb-item>
             <a-breadcrumb-item class="songAdmin">仓票</a-breadcrumb-item>
            </a-breadcrumb>
       </div>
       <div class="warehouse">
         <div class="warehouse_title">
           <img class="warehouse_title_img" src="../../image/warehouse_Img.png" alt="" srcset="">
           <div class="warehouse_title_text">
             为您推荐好券
           </div>
         </div>
         <!-- 优惠券 -->
         <div class="warehouse_box">
            <div class="warehouse_list" v-for="(item,i) in warehouseList" :key="i">
              <img src="../../image/yhq.png" class="warehouse_list_img" alt="" srcset="">
              <div class="warehouse_list_item">
                <div class="warehouse_list_item_left">
                    <div class="warehouse_list_item_hq">
                      <div class="priceyhq">￥{{item.price}}</div>
                      <div class="yhq_text">
                         袋金票
                      </div>
                    </div>
                    <div class="limitDate">
                      使用期限：{{item.userDate}}
                    </div>
                    <div class="longDate">
                       <div>
                         长期有效
                       </div>
                    </div>
                    <div class="limitDate userDetalid">
                      <div class="userDetalid_title">使用说明：</div>
                      <div>{{item.userDeteal}}</div>
                    </div>
                    <div class="limitDate payLimitDate">
                      实际支付：<span class="payLimitDateNumber">￥{{item.payUsedManey}}</span>(省200元)
                    </div>
                </div>
             
                <div class="warehouse_list_item_right" @click="goodShop">
                   <div class="month">
                     {{item.monthe}}月
                   </div>
                   <div class="purchase">
                     立即购买
                   </div>
                   <div class="numberPurchase">
                     剩余{{item.number}}件
                   </div>
                </div>
              </div>
            </div>
            
         </div>
       </div>
    </div>
  </template>
 <script>
export default {
  data() {
    return {
      warehouseList: [
        {
          price: "3000.00",
          userDate: "06.01起激活到结算账户",
          userDeteal: "可抵达扣租、服务费、运费等",
          payUsed: "(省50元)",
          payUsedManey: "2950.00",
          monthe: "6",
          number: "188"
        },
        {
          price: "3000.00",
          userDate: "07.01起激活到结算账户",
          userDeteal: "可抵达扣租、服务费、运费等",
          payUsed: "(省50元)",
          payUsedManey: "2950.00",
          monthe: "07",
          number: "188"
        },
        {
          price: "3000.00",
          userDate: "08.01起激活到结算账户",
          userDeteal: "可抵达扣租、服务费、运费等",
          payUsed: "(省50元)",
          payUsedManey: "2950.00",
          monthe: "08",
          number: "188"
        },
        {
          price: "3000.00",
          userDate: "09.01起激活到结算账户",
          userDeteal: "可抵达扣租、服务费、运费等",
          payUsed: "(省50元)",
          payUsedManey: "2950.00",
          monthe: "09",
          number: "188"
        },
        {
          price: "3000.00",
          userDate: "10.01起激活到结算账户",
          userDeteal: "可抵达扣租、服务费、运费等",
          payUsed: "(省50元)",
          payUsedManey: "2950.00",
          monthe: "10",
          number: "188"
        },
        {
          price: "3000.00",
          userDate: "11.01起激活到结算账户",
          userDeteal: "可抵达扣租、服务费、运费等",
          payUsed: "(省50元)",
          payUsedManey: "2950.00",
          monthe: "11",
          number: "188"
        }
      ]
    };
  },
  methods: {
    goodShop() {
      this.$router.push('shopSuccess');
    }
  }
};
</script>
  <style lang="less" scoped>
.warehouse {
  padding: 20px 20px 0 20px;
  .warehouse_title {
    position: relative;
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    line-height: 24px;
    .warehouse_title_img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .warehouse_title_text {
      position: absolute;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #fd4d02;
    }
  }
}
.titleNav {
  background-color: #eeeeee;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #999999;
  padding-bottom: 16px;
  .songAdmin {
    color: #fd4d02;
  }
}
.warehouse_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .warehouse_list {
    margin-top: 30px;
    width: 449px;
    height: 165px;
    position: relative;
    .warehouse_list_img {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .warehouse_list_item {
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 22px 0px 15px 22px;
      display: flex;
      justify-content: space-between;

      .warehouse_list_item_left {
        width: 196px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .warehouse_list_item_hq {
          display: flex;
          height: 21px;
          .priceyhq {
            font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #fd4d02;
            height: 21px;
            line-height: 21px;
            margin-right: 12px;
          }
          .yhq_text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #fd4d02;
            text-align: end;
            height: 21px;
            line-height: 21px;
          }
        }
        .limitDate {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff977d;

          margin-top: 6px;
          padding-left: 5px;
        }
        .userDetalid {
          display: flex;
          margin-top: 6px;
          justify-content: space-between;
          .userDetalid_title {
            width: 72px;
          }
        }
        .payLimitDate {
          margin-top: 6px;
          .payLimitDateNumber {
            color: #fd4d02;
          }
        }
        .longDate {
          width: 100%;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fd4d02;
          padding-left: 60px;
          margin-top: 5px;
        }
      }
      .warehouse_list_item_right {
        width: 110px;
        height: 100%;
        cursor: pointer;
        .month {
          font-size: 46px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fd4d02;
          opacity: 0.3;
          height: 46px;
          line-height: 46px;
        }
        .purchase {
          margin-top: 13px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fd4d02;
          height: 16px;
          line-height: 16px;
        }
        .numberPurchase {
          margin-top: 13px;
          height: 13px;
          line-height: 13px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fd4d02;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
  